import Placeholder from '../Content/Design/Placeholder'
import React from 'react';
import giulia from '../Content/Art/quadri/2.jpg';
import sara from '../Content/Art/quadri/5.jpg';
import rosa from '../Content/Art/quadri/IMG_20181128_211017.jpg';
import strade from '../Content/Art/quadri/IMG_20181117_223318.jpg';
import burrasca from '../Content/Art/quadri/7.jpg';
import moon from '../Content/Art/quadri/DSC_0131.jpg';
import marte from '../Content/Art/quadri/6.jpg';
import donna from '../Content/Art/quadri/IMG_20181113_004408.jpg'
import riotgirl1 from '../Content/Art/quadri/15.jpg';
import riotgirl2 from '../Content/Art/quadri/14.jpg';
import riotgirl3 from '../Content/Art/quadri/13.jpg';
import riotgirl4 from '../Content/Art/quadri/12.jpg';
import riotgirl5 from '../Content/Art/quadri/11.jpg';
import imagina from '../Content/Art/quadri/10.jpg';
import marina from '../Content/Art/quadri/17.jpg';
import caro from '../Content/Art/quadri/3.jpg';
import tramonto from '../Content/Art/quadri/tramonto.jpg';
import ladrocuori from '../Content/Art/quadri/ladrocuori.jpg';
import untitledportrait from '../Content/Art/quadri/untitledportrait.jpeg';
import untitledportrait2 from '../Content/Art/quadri/untitledportrait2.jpg';
import untitledportrait3 from '../Content/Art/quadri/untitledportrait3.jpg';
import capitang from '../Content/Art/quadri/capitangesu.jpg';
import ventodaest from '../Content/Art/quadri/ventodaest.jpeg';
import ricordi from '../Content/Art/quadri/ricordi.jpg';
import superhero from '../Content/Art/quadri/superhero.jpg';
import untitled4 from '../Content/Art/quadri/untitled4.jpg';
import guerraluna from '../Content/Art/quadri/guerraluna.jpg';
import untitled5 from '../Content/Art/quadri/untitled5.jpg';
import fiore from '../Content/Art/quadri/fiore.jpg';
import casas from '../Content/Art/quadri/casas.jpg';
import nuotata from '../Content/Art/quadri/nuotata.jpeg';
import sempreburrasca from '../Content/Art/quadri/sempreburrasca.jpeg';
import luna from '../Content/Art/quadri/luna.jpeg';
import portrait6 from '../Content/Art/quadri/portrait6.jpeg';
import untitled7 from '../Content/Art/quadri/untitled7.jpeg';
//min
import MINgiulia from '../Content/Art/quadri/min/2.jpeg';
import MINsara from '../Content/Art/quadri/min/5.jpeg';
import MINrosa from '../Content/Art/quadri/min/IMG_20181128_211017.jpeg';
import MINstrade from '../Content/Art/quadri/min/IMG_20181117_223318.jpeg';
import MINburrasca from '../Content/Art/quadri/min/7.jpeg';
import MINmoon from '../Content/Art/quadri/min/DSC_0131.jpeg';
import MINmarte from '../Content/Art/quadri/min/6.jpeg';
import MINdonna from '../Content/Art/quadri/min/IMG_20181113_004408.jpeg'
import MINriotgirl1 from '../Content/Art/quadri/min/15.jpeg';
import MINriotgirl2 from '../Content/Art/quadri/min/14.jpeg';
import MINriotgirl3 from '../Content/Art/quadri/min/13.jpeg';
import MINriotgirl4 from '../Content/Art/quadri/min/12.jpeg';
import MINriotgirl5 from '../Content/Art/quadri/min/11.jpeg';
import MINimagina from '../Content/Art/quadri/min/10.jpeg';
import MINmarina from '../Content/Art/quadri/min/17.jpeg';
import MINcaro from '../Content/Art/quadri/min/3.jpeg';
import MINtramonto from '../Content/Art/quadri/min/tramonto.jpeg';
import MINladrocuori from '../Content/Art/quadri/min/ladrocuori.jpeg';
import MINuntitledportrait from '../Content/Art/quadri/min/untitledportrait.jpeg';
import MINuntitledportrait2 from '../Content/Art/quadri/min/untitledportrait2.jpeg';
import MINuntitledportrait3 from '../Content/Art/quadri/min/untitledportrait3.jpeg';
import MINcapitang from '../Content/Art/quadri/min/capitangesu.jpeg';
import MINventodaest from '../Content/Art/quadri/min/ventodaest.jpeg';
import MINricordi from '../Content/Art/quadri/min/ricordi.jpeg';
import MINsuperhero from '../Content/Art/quadri/min/superhero.jpeg';
import MINuntitled4 from '../Content/Art/quadri/min/untitled4.jpeg';
import MINguerraluna from '../Content/Art/quadri/min/guerraluna.jpeg';
import MINuntitled5 from '../Content/Art/quadri/min/untitled5.jpeg';
import MINfiore from '../Content/Art/quadri/min/fiore.jpeg';
import MINcasas from '../Content/Art/quadri/min/casas.jpeg';
import MINnuotata from '../Content/Art/quadri/min/nuotata.jpeg';
import MINsempreburrasca from '../Content/Art/quadri/min/sempreburrasca.jpeg';
import MINluna from '../Content/Art/quadri/min/luna.jpeg';
import MINportrait6 from '../Content/Art/quadri/min/portrait6.jpeg';
import MINuntitled7 from '../Content/Art/quadri/min/untitled7.jpeg';

export default [
  {
    title:'103 sfumature di giallo',
    tags:['#tag','#tag','#tag'],
    anno:'2017',
    medium:'Oil on canvas',
    dim:'50cmx50cm',    
    img:giulia,
    MINimg:MINgiulia,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Fiore che non morirà',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on canvas',
    dim:'50cmx50cm',    
    img:fiore,
    MINimg:MINfiore,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Vento da est',
    tags:['#tag','#tag','#tag'],
    anno:'2020',
    medium:'Oil on canvas',
    dim:'30cmx30cm',    
    img:ventodaest,
    MINimg:MINventodaest,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Sempre Burrasca',
    tags:['#tag','#tag','#tag'],
    anno:'2020',
    medium:'Oil on canvas',
    dim:'30cmx50cm',   
     img:sempreburrasca,
     MINimg:MINsempreburrasca,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Untitled portrait 3',
    tags:['#tag','#tag','#tag'],
    anno:'2020',
    medium:'Oil on canvas',
    dim:'60cmx200cm',   
     img:untitledportrait3,
     MINimg:MINuntitledportrait3,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Nuotata nella natura',
    tags:['#tag','#tag','#tag'],
    anno:'2019',
    medium:'Oil on cardboard',
    dim:'60cmx45cm',    
    img:nuotata,
    MINimg:MINnuotata,
    height: 300,
    component:<Placeholder/>
  },
  {
    title:'Casa remota',
    tags:['#tag','#tag','#tag'],
    anno:'2017',
    medium:'Oil on wood',
    dim:'20cmx15cm ',    
    img:casas,
    MINimg:MINcasas,
    height: 300,
    component:<Placeholder/>
  },
  {
    title:'Ricordi',
    tags:['#tag','#tag','#tag'],
    anno:'2020',
    medium:'Oil on canvas',
    dim:'30cmx45cm',    
     img:ricordi,
     MINimg:MINricordi,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Ladro di cuori',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Mixed Media Scratch',
    dim:'200cmx150cm',    
    img:ladrocuori,
    MINimg:MINladrocuori,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Guerra al chiaro di luna',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Mixed Media Scratch',
    dim:'100cmx100cm',    
    img:guerraluna,
    MINimg:MINguerraluna,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'--Untitled',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Mixed Media Scratch',
    dim:'150cmx80cm',    
    img:untitled5,
    MINimg:MINuntitled5,
    height: 300,
    component:<Placeholder/>
  },
  {
    title:'Superhero',
    tags:['#tag','#tag','#tag'],
    anno:'2019',
    medium:'Oil on canvas',
    dim:'60cmx100cm',   
     img:superhero,
     MINimg:MINsuperhero,
    height: 800,
    component:<Placeholder/>
  },
  {
  title:'Luna',
  tags:['#tag','#tag','#tag'],
  anno:'2017',
  medium:'Oil on canvas',
  dim:'50cmx50cm',    
  img:luna,
  MINimg:MINluna,
  height: 500,
  component:<Placeholder/>
},
{
  title:'A portrait',
  tags:['#tag','#tag','#tag'],
  anno:'2017',
  medium:'Oil on canvas',
  dim:'30cmx50cm',   
   img:portrait6,
   MINimg:MINportrait6,
  height: 800,
  component:<Placeholder/>
},
{
  title:'.Untitled',
  tags:['#tag','#tag','#tag'],
  anno:'2017',
  medium:'Oil on cardboard',
  dim:'30cmx20cm',   
   img:untitled7,
   MINimg:MINuntitled7,
  height: 300,
  component:<Placeholder/>
},
  {
    title:'Untitled Portrait 2',
    tags:['#tag','#tag','#tag'],
    anno:'2019',
    medium:'Oil on canvas',
    dim:'30cmx30cm',    
    img:untitledportrait2,
    MINimg:MINuntitledportrait2,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Untitled Portrait',
    tags:['#tag','#tag','#tag'],
    anno:'2019',
    medium:'Oil on canvas',
    dim:'30cmx40cm',    
    img:untitledportrait,
    MINimg:MINuntitledportrait,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Capitan Gesù',
    tags:['#tag','#tag','#tag'],
    anno:'2019',
    medium:'Oil on canvas',
    dim:'60cmx60cm',    
    img:capitang,
    MINimg:MINcapitang,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Carolina',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on canvas',
    dim:'20cmx20cm',    
    img:caro,
    MINimg:MINcaro,
    height: 400,
    component:<Placeholder/>
  },
  {
    title:'Tramonto',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil o',
    dim:'15cmx20cm',    
    img:tramonto,
    MINimg:MINtramonto,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'-Untitled-',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on canvas',
    dim:'40cmx40cm',    
    img:untitled4,
    MINimg:MINuntitled4,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Marina1',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on canvas',
    dim:'20cmx15cm',    
    img:marina,
    MINimg:MINmarina,
    height: 300,
    component:<Placeholder/>
  },
  {
    title:'Untitled1',
    tags:['#tag','#tag','#tag'],
    anno:'2017',
    medium:'Oil on cardboard',
    dim:'30cmx20cm',    
    img:sara,
    MINimg:MINsara,
    height: 300,
    component:<Placeholder/>
  },
  {
    title:'Rosa ventosa',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on wood',
    dim:'50cmx100cm',   
     img:rosa,
     MINimg:MINrosa,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Strade',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on wood',
    dim:'50cmx150cm',   
     img:strade,
     MINimg:MINstrade,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Burrasca',
    tags:['#tag','#tag','#tag'],
    anno:'2017',
    medium:'Oil on cardboard',
    dim:'70cmx40cm',    
    img:burrasca,
    MINimg:MINburrasca,
    height: 300,
    component:<Placeholder/>
  },
  {
    title:'Start Tap',
    tags:['#tag','#tag','#tag'],
    anno:'2017',
    medium:'Oil on canvas',
    dim:'50cmx50cm',   
     img:moon,
     MINimg:MINmoon,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Untitled2',
    tags:['#tag','#tag','#tag'],
    anno:'2016',
    medium:'Oil on canvas',
    dim:'50cmx50cm',   
    img:marte,
    MINimg:MINmarte,
    height: 500,
    component:<Placeholder/>
  },
  {
    title:'Untitled3',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Oil on wood',
    dim:'50cmx100cm',   
     img:donna,
     MINimg:MINdonna,
    height: 800,
    component:<Placeholder/>
  },
  {
    title:'Riot Girl 1',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Digital',
    dim:'4000px x 4000px',   
    img:riotgirl1,
    MINimg:MINriotgirl1,
    height: 400,
    component:<Placeholder/>
  },
  {
    title:'Riot Girl 2',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Digital',
    dim:'4000px x 4000px',   
    img:riotgirl2,
    MINimg:MINriotgirl2,
    height: 400,
    component:<Placeholder/>
  },
  {
    title:'Riot Girl 3',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Digital',
    dim:'4000px x 4000px',   
    img:riotgirl3,
    MINimg:MINriotgirl3,
    height: 400,
    component:<Placeholder/>
  },
  {
    title:'Riot Girl 4',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Digital',
    dim:'4000px x 4000px',   
    img:riotgirl4,
    MINimg:MINriotgirl4,
    height: 400,
    component:<Placeholder/>
  },
  {
    title:'Riot Girl 5',
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Digital',
    dim:'4000px x 4000px',   
    img:riotgirl5,
    MINimg:MINriotgirl5,
    height: 400,
    component:<Placeholder/>
  },
  {
    title:"Fabbrica della fantasia",
    tags:['#tag','#tag','#tag'],
    anno:'2018',
    medium:'Digital',
    dim:'4000px x 4000px',   
    img:imagina,
    MINimg:MINimagina,
    height: 400,
    component:<Placeholder/>
  },
]
