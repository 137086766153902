import React from 'react';
import example from './example.gif'
import './ProjectManagement.css';

function ProjectManagement(props) {

    return (
        <div className='ProjectManagement' style={{height:'100%',display:'flex',flexDirection:'column'}}>
            <div className='projectRow'>
                <h1 className='projectTitle'>Collapsible Project Folder</h1>
                <div className='projectTags'>
                    <span>Ui -</span>
                    <span>Microinteractions </span>
                </div>
            </div>
            <div className='aprojectRowfull'>
                <div className='sandbox' >
                    <img alt='Trasformation of folder' style={{width:'350px'}} src={example} />
                </div>
            </div>
        </div>
    );
}

export default ProjectManagement;
