import React, { useEffect, useState } from 'react';
import {
    useParams,
    useHistory
} from "react-router-dom";
import items from './data'
import Card from './Card'
function Artwork(props) {
    let { id } = useParams();
    let history = useHistory();
    const [_item, setItem] = useState({})
    useEffect(() => {
        items.forEach((item) => {
            if (!id) {
                props.closeAction()
            }
            if (id && !props.active) {
                props.openAction()
            }
            if (item.title === id) {
                setItem(item)
            }
        })
    }, [id, props.active]);

    return (
        <div className='ArtContainer' >
            <span style={{ marginTop: 10, marginBottom: 15 }}>
                <a className='link' style={{ color: '#0606068c', textDecoration: 'underline', cursor: 'pointer' }} onClick={(e) => { history.push('/art'); props.closeAction() }}>art/</a>
                <span><strong> {id}</strong></span>
            </span>
            
            <div className='infoRow' >
                <div className='infoTitle' ><p><strong>Year</strong></p></div>
                <div className='infoCont'><p>{_item.anno}</p></div>
            </div>
            <div className='infoRow' >
                <div className='infoTitle' ><p><strong>Medium</strong></p></div>
                <div className='infoCont' ><p>{_item.medium}</p></div>
            </div>
            <div className='infoRow' >
                <div className='infoTitle' ><p><strong>Dimensions</strong></p></div>
                <div className='infoCont'  ><p>{_item.dim}</p></div>
            </div>
            <span style={{marginTop:10}}>
                More on <a className='link' href='https://www.instagram.com/sempre_burrasca' target="_blank">@sempre_burrasca</a></span>
            <div className='cardena'>
                <Card img={_item.img} />
            </div>
            
        </div>
    );
}
export default Artwork;
