import React from 'react';
import bg from './bg.jpg'
import character from './character.png'
import './ProjectCard.css';

function ProjectCard(props) {

    return (
        <div className='ProjectCard' >
            <div className='projectRow'>
                <h1 className='projectTitle'>Collectible Card Character</h1>
                <div className='projectTags'>
                    <span>Ui </span>
                </div>
            </div>
            <div className='aprojectRowfull'>
                <div className='sandbox' style={{
                    backgroundImage:'url('+bg+')',
                    backgroundPosition:'center',
                    height:'100%'
                }}>
                    <img alt='Character Card' style={{width:'350px'}} src={character} />
                </div>
            </div>
        </div>
    );
}

export default ProjectCard;
