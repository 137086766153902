import React from 'react';
import Menu from './components/Menu';
import Home from './components/Home/Home';
import Design from './components/Design/Design';
import Art from './components/Art/Art';
import Lab from './components/Lab/Lab'
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import Gianluca from './components/Gianluca/Gianluca';
import NFT from './components/Design/NFT';

function App(props) {

  return (
    <Router>
    <div className="App">
        <Menu />
        <Switch>
          <Route path="/art" component={Art} />
          <Route path="/gianlucacomandini" component={Gianluca} />
          <Route path="/lab" component={Lab} />
          <Route path="/portfolio" component={NFT} />
          <Route path="/" component={Home} />
        </Switch>
    </div>
    </Router>
  )
}

export default App;
