import React from 'react';
import hamburger from '../Content/Design/Hamburger/hamburger.gif';
import Hamburger from '../Content/Design/Hamburger/Hamburger';
import netread from '../Content/Design/Netread/heronetread.png';
import Netread from '../Content/Design/Netread/Netread';
import characterCard from '../Content/Design/ProjectCard/Artboard.png'
import ProjectCard from '../Content/Design/ProjectCard/ProjectCard';
import sara from '../Content/Design/Sara/cover.jpg';
import Sara from '../Content/Design/Sara/Sara';
import projman from '../Content/Design/ProjectManagement/cover.gif'
import ProjectMan from '../Content/Design/ProjectManagement/ProjectManagement';
import multitrack from '../Content/Design/Multitrack/cover.gif';
import Multitrack from '../Content/Design/Multitrack/Multitrack';
export default [
  
  {  
    title:'Multitrack Stream App',
    tags:['#Ux','#Ui'],
    css: 'url('+multitrack+')',
    height: 300,
    component:<Multitrack/>
  },
  {
    title:'Collapsible Project Folder',
    tags:['#Ui','#Microinteractions'],
    css: 'url('+projman+')',
    height: 300,
    component:<ProjectMan/>
  },
  {
    title:'Hamburger Menu',
    tags:['#Microinteractions','#Ui'],
    css: 'url('+hamburger+')',
    height: 300,
    component:<Hamburger/>
  },
  {
    title:'Netread',
    tags:['#Ui','#Ux'],
    css: 'url('+netread+')',
    height: 300,
    component:<Netread/>
  },
  {
    title:'Collectible Card Character',
    tags:['#Ui'],
    css: 'url('+characterCard+')',
    height: 300,
    component:<ProjectCard/>
  },
  {
    title:'Sara AI',
    tags:['#AI','#CreativeCoding'],
    css: 'url('+sara+')',
    height: 300,
    component:<Sara/>
  },
  ]
  