import React, { useEffect, useState } from 'react';
import { useHistory,useRouteMatch, Switch, Route, } from "react-router-dom";
import styled from 'styled-components'
import { a } from 'react-spring'
import InfiniteSlider from './Slider'
import items from './items'
import Job from './Job'
import './Design.css';

const Main = styled.div`
  height: 400px;
`

const Content = styled.div`
  width: 100%;
  height: 100%;
  padding: 40px 60px;
`

const Marker = styled.span`
  position: absolute;
  top: 0px;
  left: 100px;
  font-family: 'Source Code Pro', monospace;
  color: black;
  font-weight: 700;
  text-decoration: underline;
  margin: 5px 0;
`
const Tagger = styled.span`
  position: absolute;
  bottom: 0px;
  left: 100px;
  font-family: 'Source Code Pro', monospace;
  color: black;
  font-weight: 500;
  margin: 5px 0;
`

const Image = styled(a.div)`
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23);
  cursor: default;
  cursor: url("https://github.com/chenglou/react-motion/raw/master/demos/demo8-draggable-list/cursor.png") 10 10, auto;
`

function Design(props) {
  let history = useHistory();
  let { path, url } = useRouteMatch();
  const [active, setActive] = useState(false)
  useEffect((path) => {
    if(path==='/design'){
      setActive(false)
    }else{
      setActive(true)
    }
  },[]);
  return (
    <Main>
    <InfiniteSlider items={items} width={450} visible={3}>
      {({ css, title, tags }, i) => (
        <Content>
          <Marker onClick={(e) => { setActive(true); history.push(`${url}/${title}`) }} style={{cursor:'pointer'}}>{title}</Marker>
          <Image  style={{ backgroundImage: css }} />
          <Tagger>{tags.map(t=>t+' ')}</Tagger>
        </Content>
      )}
    </InfiniteSlider>
    <div style={{
        position: 'fixed',
        width: '100vw',
        height: '100vh',
        left: 0,
        top: active ? 0 : 2000,
        background: 'white',
        transition: '.5s all ease-in-out',
        zIndex: 8
      }}>
        <Switch>
          <Route exact path={path}>
          <Job closeAction={()=>setActive(false)} active={active} openAction={()=>setActive(true)}/>
          </Route>
          <Route path={`${path}/:id`}>
            <Job closeAction={()=>setActive(false)}  active={active} openAction={()=>setActive(true)} />
          </Route>
        </Switch>
      </div>
  </Main>
  );
}

export default Design;
