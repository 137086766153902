import React from 'react';
import hero from './heronetread.png';
import phoneMock from './mock.png';
import gif1 from './net1.gif';
import gif2 from './net2.gif';
import gif3 from './net3.gif';
import './Netread.css';

function Hamburger(props) {

    return (
        <div className='Netread' style={{ width: '100%' }}>
            <div className='projectRow'>
                <h1 className='projectTitle'>Netread</h1>
                <div className='projectTags'>
                    <span>Ui - </span>
                    <span>Ux </span>
                </div>
            </div>
            <div className='projectRowfull'>
                <div className='sandbox' style={{
                    backgroundImage: 'url(' + hero + ')',
                    backgroundPosition: 'center',
                }}>
                </div>
            </div>
            <div className='projectRow'>
                <h2>About</h2>
                <div className='twoColumns'>
                    <p style={{ flex: 1 }}>
                        An app for reading comics
                        on-demand and interact with the community.
                        Scalable platform sold to publishing houses.<br />
                        <br />
                        <strong>Benefits</strong><br />
                        - Fight piracy<br />
                        - Cost reduction for experimentation<br />
                        - Visibility for emerging artists<br />
                        - Portability of  collections<br />
                        - VR Comics<br />
                    </p>
                    <img alt='Mobile mockup' style={{ flex: 1 }} src={phoneMock} />
                </div>
            </div>
            <div className='projectRow'>
                <h2>Analysis</h2>
                <p>
                    <strong>Cluster</strong><br />
                </p>
                <div className='threeColumns' >
                    <p>
                        <i>Gender</i><br />
                        - 85% uomini<br />
                        - 13% donne<br />
                        - 3% altro<br />
                    </p>
                    <p>
                        <i>Age</i><br />
                        - 7,7% &lt;20<br />
                        - 60,7% 20-30<br />
                        - 30,7% 30-60<br />
                        - 0,9%  &gt;60<br />
                    </p>
                    <p>
                        <i>Tech Level</i><br />
                        -  1,8% low<br />
                        - 90,4% standard<br />
                        - 7,8% high<br />
                    </p>
                </div>
                <div className='twoColP' >
                    <p style={{
                        margin: 5,
                        flex: 1
                    }}>
                        <strong>Surveys</strong><br />
                        - 21.4% prefer to read both digitally and paper format, 14.3% digitally, the
                        remaining 64.3% totally paper<br />
                        - 75% also read online scans<br />
                        - 85.7% would like a legal platform to enjoy comic book content, where 68%
                        would be willing to pay &lt;5 € per month, the remaining 32% between 5 and 10 €<br />
                        - Online communities and specialized sites are the most used platforms to find out about
                        comics<br />
                    </p>
                    <p style={{
                        margin: 5,
                        flex: 1
                    }}>
                        <strong>Interviews</strong><br />
                        - Users prefer paper for an affective and collectible value<br />
                        - Pirate only for exits not yet arrived in the national territory (always remain
                        updated)<br />
                        - Plan budget weekly or monthly<br />
                        - The "famous commercial" typology wins the game<br />
                        - They read an average of 7/8 comics per month<br />
                    </p>
                </div>
            </div>
            <div className='projectRow' style={{marginBottom:50}}>
                <h2>Product</h2>
                <div className='prodThree' >
                    <img alt='App prototype' src={gif1} />
                    <img alt='App prototype' src={gif2} />
                    <img alt='App prototype' src={gif3} />
                </div>
            </div>
        </div>
    );
}

export default Hamburger;
