import React from 'react';


function Gianluca(props) {
    return (
        <div className='Gianluca'>
            <h1>Gian Luca Comandini NFT</h1>
            <h2>Claim NFT</h2>
        </div>
    );
}

export default Gianluca;
