import React,{useState} from 'react';
import {
  Link
} from "react-router-dom";
import About from './About'
import './Menu.css'

function Menu() {
  const [open,setOpen]=useState(false)
  return (
    <div className='nav'>
      <div id='m-home' >
        <Link className='link' to="/">glc.design</Link>
      </div>
      <div id='m-about' >
        <span className='link' onClick={(e)=>setOpen(!open)} >{open?'close':'about me'}</span>
      </div>
      <div  id='m-portfolio'>
        <Link className='link'  to="/portfolio">portfolio</Link>
      </div>
      <div  id='m-art' >
        <Link className='link'  to="/art">art</Link>
      </div>
      <div className={open?'contAb open':'contAb'}>
        <About/>
      </div>
    </div>
  );
}

export default Menu;
