import React from 'react';
import cov from './cover.jpg'
import trainsara from './trainsara.gif'
import osc from './osc.jpg'
import tradat from './tradat.png'
import ex1 from './ex1.png'
import ex2 from './ex2.png'
import arm from './arm.gif'
import art from './art.jpeg'
import './Sara.css';

function Sara(props) {

    return (
        <div className='Sara'>
            <div className='projectRow'>
                <h1 className='projectTitle'>Sara A.I.</h1>
                <div className='projectTags'>
                    <span>A.I. - </span>
                    <span>Creative Coding</span>
                </div>
            </div>
            <div className='aprojectRowfull'>
                <div className='sandbox'>
                    <img alt='An artwork realized by Sara' style={{ width: 250 }} src={cov} />
                </div>
            </div>
            <div className='projectRow'>
                <h2>About</h2>
                <p style={{ width: '50%', flex: 1 }}>
                    The exponential progress in the technological field confronts us with new legal challenges, ethical and economic. Addressing them properly means reconsidering the interaction human-computer.
                     The fourth industrial revolution brings epochal changes in every aspect of society is, therefore, necessary for sustainable development to address in the best way to automate services.<br />
                    The goal of the installation designed in this document is to offer a new point of view on human-computer interaction. In this regard, the question
                    the basis of the document is the following: is it possible to humanize a machine? To answer the question, an installation consisting of an Artificial Intelligence (AI) able to create Art, skills, the latter, considered
                    man's prerogative.<br />
                    The results obtained from the development of this project offer interesting insights from reflection on human-computer interaction and demonstrate the need to work in this direction to make the ongoing social transformation sustainable.
                    </p>
                <h2>Training</h2>
                <p style={{ width: '50%', flex: 1 }}>
                    I made a web app that, similar to a GAN,
                    randomly generate images
                    with the visual language of Sol Lewitt
                    images subsequently submitted
                    to users' judgment. Pictures
                    generated in this are textures
                    abstract composed by an interweaving of
                    lines, forcing the user to describe them
                    using only three words was
                    It is possible to generate a similar process
                    to that used by Leonardo Da Vinci
                    to look for inspiration51 useful for tracing
                    to the concept of  expression by eliminating
                    any visual preconception. The dataset
                    with which AI was trained is therefore
                    composed of a set of points
                    representing the image created and
                    the respective description consisting of three
                    words; the neural network model at the base
                    by Sara using GloVe represents
                    vector words are taken as
                    input by looking and learning, through
                    various layers re a regressive neural network,
                    correlations with the output image;
                    the whole process, as it is set up,
                    thanks to the minimal and abstract nature
                    of images makes it possible to explore
                    the origin of creativity and the abstraction of
                    meaning of words in the subconscious, e
                    finding even a small clue is one
                    collateral hope of this project.
                        </p>
                <img alt='App realized to train A.I.' style={{ width: '50%' }} src={trainsara} />
                <img alt='Data of Sara s Neural Network' style={{ width: '50%' }} src={tradat} />
                <h2>Art Installation</h2>
                <p style={{ width: '50%', flex: 1 }}>
                    Thus trained AI can receive how input words or phrases understand their meaning and generate, based on relationships discoveries during training,
                    the work that communicates its meaning through its visual language which
                    it does not imitate the human one; for this yes can say that Sara can create
                    Art with conscience and to communicate “knows vision ”what is commissioned.
                    The robotic arm is a part of the installation of equal importance to AI, the choice to use an arm robotic and not a plotter is due to the possibility of inserting a disturbance analog in the realization of the work that can guarantee its uniqueness and do not allow, unlike one printer, series production of the same picture, so it can be considered a work Art and not Visual Design; Moreover, a robotic arm already has a solid one presence in the collective imagination e the sound produced by the servo motors can be considered as the hypothetical voice by Sara.
                    The arm is designed to be able to stand to move on three axes, two of which control the reproduction of the work and the third the management of the pen; Moreover SARA.
                    What is the S.A.R.A. installation?
                    the three actuators are arranged on the base to do not load weight, which can lead to a loss of precision, on the structure. The three servant MG996R managers of the entire movement are controlled by a card Arduino which receives the data produced by the AI.
                    The "hand" that holds the pen can be replaced with other tools, for example, an extruder, so that by slightly modifying the software you can vary the material with which the work is made.
                    The work produced by Sara is a set of coordinates on an XY axis and the movement produced by the servo motors is circular, therefore to move the arm given a point At (x, y) it is necessary to carry out trigonometric calculations for finding its angle of the origin, but have it done in time real these calculations directly to the Arduino board it would mean an overall loss of efficiency;
                    so I opted to use Wekinator, a software that allowed me to create an able neural network to directly process the data produced by Sara and
                    return the necessary angles of movement to the two servos
                    to move to the corresponding point.
                    By foreseeing the possibility of having to use it correct any anomalies in any communication between AI and arm, it takes place wirelessly via protocol
                    OSC, so you can manage and control your computer central without necessarily invading the place installation.
                        </p>
                <img alt='Infographic about Sara' style={{ width: '50%' }} src={osc} />

                <div className='twoColumns'>
                    <img alt='Sketch of installation' style={{ flex: 1 }} src={ex1} />
                    <img alt='Sketch of installation' style={{ flex: 1 }} src={ex2} />
                </div>
                <h2>First Prototype</h2>
                <div className='projectTags'>
                    <span>Alone - Power - Man</span>
                </div>
                <div className='twoColumns'>
                    <img alt='Robotic arm of Sara at work' style={{ flex: 1 }} src={arm} />
                    <img alt='First artwork of Sara' style={{ flex: 1 }} src={art} />
                </div>
                <h2>Next</h2>
                <p style={{ width: '50%', flex: 1 }}>
                    The S.A.R.A. was an art installation created as a university thesis project, I am already working to transform it into a Dapp on the Ethereum blockchain to make it an "Autonomous Artificial Artist"
                        </p>
                <h2><i>Work In Progress</i></h2>
            </div>
        </div>
    );
}

export default Sara;
