import React from 'react';
import cov from './cover2.png';
import homemock from './home.png';
import loga from './login.png';
import flow from './flow.png'
import add from './add.png'
import sessh from './sessh.png'
import sessg from './sessg.png'
import Group from './Group.png'
import down from './Detail.png'
import guest from './guest.png'
import host from './hos.png'
import './Multitrack.css'
function Multitrack(props) {
    return (
        <div className='Multitrack'>
            <div className='projectRow'>
                <h1 className='projectTitle'>Multitrack Stream App</h1>
                <div className='projectTags'>
                    <span>Ui - </span>
                    <span>Ux</span>
                </div>
            </div>
            <div className='aprojectRowfull'>
                <div className='sandbox'>
                    <img alt='An artwork realized by Sara' style={{ width: 350 }} src={cov} />
                </div>
            </div>
            <div className='projectRow'>
                <h2>About</h2>
                <div className='twoColumns'>
                    <p style={{ width: '50%', flex: 1 }}>
                        An application for mobile devices that allows the customer to remotely record entire interviews and podcasts.<br />
                        In the customer's workflow, the product is used when it is not possible to receive guests in the studio or otherwise use the correct hardware.
                        <br /><br />
                        <strong>Requests</strong><br />
                    - Live conversation <br />
                    - Multitrack recording <br />
                    - High-quality audio<br />
                    - Simple guest invitation
                    </p>
                    <img alt='Home view' style={{ flex: 1 }} src={homemock} />
                </div>
            </div>
            <div className='projectRow'>
                <h2>Guest & Host</h2>
                <img alt='Flow view' style={{ flex: 1, width: '50vw' }} src={flow} />
                <div className='twoColumns'>
                    <p style={{ width: '50%', flex: 1 }}>
                        The most important aspect for the customer is the possibility (when it is not possible to record in the studio) to have guests within his podcast simply and effectively even for those who are not familiar with technology while maintaining high audio quality and the division into single tracks so that the files can be processed in post-production.
                    Two types of users were found:<br />
                        <strong>Host</strong><br />
                    The customer, the one who records the podcast. Its use is more frequent and detailed.<br />
                        <strong>Guest</strong><br />
                    The guest user, its use is occasional for this does not require an account and can also access the application via a browser.
                    </p>
                    <img alt='Schedule view' style={{ flex: 1 }} src={loga} />
                </div>
                <div className='twoColumns'>
                    <img alt='Guest view' style={{ flex: 1 }} src={guest} />
                    <img alt='Host view' style={{ flex: 1 }} src={host} />
                </div>
                <div className='twoColumns'>
                    <p style={{ width: '50%', flex: 1 }}>
                        The Host can manage and schedule recording sessions with one or more Guests, each of whom will have a unique code with which he will be able to access the session when recording the podcast.
                    </p>
                    <img alt='Add view' style={{ flex: 1 }} src={add} />
                </div>
            </div>
            <div className='projectRow'>
                <h2>Recording Session</h2>
                <div className='twoColumns'>
                    <img alt='Home view' style={{ flex: 1 }} src={sessh} />
                    <img alt='Home view' style={{ flex: 1 }} src={sessg} />
                </div>
                <p style={{ width: '50%', flex: 1 }}>
                    During the recording session, while the Guest has freedom of action only on itself, the Host has full access to the management of the transmission. The Host can change individual guests, start or stop the recording, always keeping everything under control.
                    </p>
                <img alt='Flow view' style={{ flex: 1, width: '50vw' }} src={Group} />
                <div className='twoColumns'>
                    <p style={{ width: '50%', flex: 1 }}>
                    The audio recording and transmission process is very simple, once the session is started on the devices of the individual users two audio streams are created. Compressed and light audio that allows real-time transmission with all users and another in high quality that is recorded locally. Once the transmission is over, the track recorded locally is uploaded to the Host which can then send it via email.
                    </p>
                    <img alt='Home view' style={{ flex: 1 }} src={down} />
                </div>
            </div>
        </div>
    );
}

export default Multitrack;
