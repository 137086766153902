import React, { useState,useEffect} from 'react'
import { useHistory, useRouteMatch, Switch, Route, } from "react-router-dom";
import { useGesture } from 'react-use-gesture'
import Artwork from './Artwork'
import data from './data'
import './Art.css'

function Portfolio() {
  let history = useHistory();
  let { path, url } = useRouteMatch();
  const [active, setActive] = useState(false)
  const columns = 5
  const [items, set] = useState(data)
  const [k, setK] = useState(true)
  const width = (window.innerWidth<1000)?1200:(window.innerWidth - 30)
  useEffect(() => {
    if(k){
    set(shuffle(data))
      setK(false)
  }
  },[k]);

  let heights = new Array(columns).fill(0)
  let gridItems = items.map((child, i) => {
    const column = heights.indexOf(Math.min(...heights))
    const xy = [(width / columns) * column, (heights[column] += child.height / 2) - child.height / 2]
    return { ...child, xy, width: width / columns, height: child.height / 2 }
  })
  function shuffle(a) {
    for (let i = a.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [a[i], a[j]] = [a[j], a[i]];
    }
    return a;
}
  const bind = useGesture({
    onDrag: (({ offset: [x,y], vxvy: [vx] }) =>{document.getElementsByClassName('list')[0].scroll(-x,-y,'smooth')}),
    onWheel:(({ offset: [, y], vxvy: [, vy] }) =>{("ontouchstart" in document.documentElement)?document.getElementsByClassName('list')[0].scroll(0,y):document.getElementsByClassName('list')[0].scroll(0,y)})
  })
  return (
    <div {...bind()}  className="list" style={{ height: Math.max(...heights) }}>
      {gridItems.map((item, key) => (
        <div className='item' key={key} style={{ overflowY:("ontouchstart" in document.documentElement)?'hidden':'hidden',height: item.height, width: item.width, transform: `translate3d(${item.xy[0]}px,${item.xy[1]}px,0)` }}>
          <div
            className='card'
            onClick={(e) => { setActive(true); history.push(`${url}/${item.title}`) }}
            style={{ backgroundImage: `url(${item.MINimg})`, cursor: 'pointer' }} />
        </div>
      ))}
      <div className={active ? 'subContain open' : 'subContain'} >
        <Switch>
          <Route exact path={path}>
          <Artwork closeAction={()=>setActive(false)} active={active} openAction={()=>setActive(true)}/>
          </Route>
          <Route path={`${path}/:id`}>
          <Artwork closeAction={()=>setActive(false)}  active={active} openAction={()=>setActive(true)} />
          </Route>
        </Switch>
      </div>
    </div>
  )
}

export default Portfolio
