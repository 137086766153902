import React from 'react'
import { useSpring, animated, to } from 'react-spring'
import { useGesture } from 'react-use-gesture'
import './Card.css'

const calcX = (y, ly) => -(y - ly - window.innerHeight / 2) / 20
const calcY = (x, lx) => (x - lx - window.innerWidth / 2) / 20


document.addEventListener('gesturestart', e => e.preventDefault())
document.addEventListener('gesturechange', e => e.preventDefault())

function Card(props) {
    const domTarget = React.useRef(null)
    const [{ x, y, rotateX, rotateY, rotateZ, zoom, scale }, set] = useSpring(() => ({
        rotateX: 0,
        rotateY: 0,
        rotateZ: 0,
        scale: 1,
        zoom: 0,
        x: 0,
        y: 0,
        config: { mass: 5, tension: 350, friction: 40 }
    }))

    const [drag, setDrag] = React.useState(false)

    const bind = useGesture(
        {
            onDragStart: () => setDrag(true),
            onDrag: ({ offset: [x, y] }) => set({ x, y, rotateX: 0, rotateY: 0, scale: 1 }),
            onDragEnd: () => setDrag(false),
            onPinch: ({ offset: [d, a] }) => set({ zoom: d / 200, rotateZ: a }),
            onMove: ({ xy: [px, py], dragging }) => !dragging && set({ rotateX: calcX(py, y.get()), rotateY: calcY(px, x.get()), scale: 1.1 }),
            onHover: ({ hovering }) => !hovering && set({ rotateX: 0, rotateY: 0, scale: 1 }),
            onWheel: ({ offset: [, y] }) => set({ zoom: y > 0 ? ((y / 100) / 2) : 0, rotateZ: 0 })
        },
        { domTarget, eventOptions: { passive: false } }
    )

    React.useEffect(bind, [bind])

    return (
        <animated.div
            ref={domTarget}
            className={`${drag ? 'dragging' : ''}`}
            style={{
                width: 'auto',
                transform: 'perspective(600px)', x, y, scale: to([scale, zoom], (s, z) => s + z), rotateX, rotateY, rotateZ
            }}
        >
            <img alt='Paint' className='paintSingleCard' src={props.img} />
        </animated.div>
    )
}

export default Card;
