import React from 'react';
import breakmicro from './breakmicro.png'
import './Hamburger.css';

function Hamburger(props) {

    const handleClick = () => {
        document.getElementsByClassName('menu-toggle')[0].classList.toggle('open')
    }
    return (
        <div className='Hamburger'>
            <div className='projectRow'>
                <h1 className='projectTitle'>Hamburger Menu</h1>
                <div className='projectTags'>
                    <span>Ui - </span>
                    <span>Ux - </span>
                    <span>Micro interactions </span>
                </div>
            </div>
            <div className='aprojectRowfull'>
                <span className='tryme'>Try me</span>
                <div className='sandbox'>
                    <div class="menu-toggle first" onClick={handleClick}>
                        <i></i>
                    </div>
                </div>
            </div>
            <div className='projectRow'>
                <div>
                <h2>Breaking it down</h2>
                <img alt='Transformation of hamburger' src={breakmicro} />
                    <p>
                        <strong>Triggers /</strong> the micro interaction starts with the user's click.<br />
                        <strong>Rules /</strong> the first two sticks move to form an X, while the last one remains still as a support.<br />
                        <strong>Feedback /</strong> animation is smooth with easing at the beginning and end. <br />
                    </p>
                </div>
            </div>
            <div>
            </div>
        </div>
    );
}

export default Hamburger;
