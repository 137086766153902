import React, {useState } from 'react';
import './Lab.css'

function Item(props) {
    const [is, setIs] = useState(props.is ? props.is : false)
    const [hover, setHover] = useState(false)

    return (
        <div className='item'
            onPointerEnter={(e) => setHover(true)}
            onPointerLeave={(e) => setHover(false)}
        >
            <div className='folder'
            style={{
                opacity:is?.5:.3
            }}
            >
                <div className='aaa' />
                <div className={hover?'bbb hover':'bbb'} />
            </div>
            {is ?
                <span>Title</span>
                : <div style={{ backgroundColor: 'gray', height: 8, width: '50%' }}></div>
            }
        </div>
    );
}

export default Item;
