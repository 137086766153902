import React from 'react';

function Placeholder(props) {
    return (
        <div>
            <div>
                <h1>Hamburger Menu</h1>
                <div>
                    <span>Ui </span>
                    <span>Ux </span>
                    <span>Micro-interactions </span>
                </div>
            </div>
            <div>
                <img alt='placeholder' src='https://via.placeholder.com/500x300' />
            </div>
            <div>
                <p>
                    Breve descrizione del progetto<br />
                Breve descrizione del progetto <br />
                Breve descrizione del progetto <br />
                Breve descrizione del progetto <br />
                Breve descrizione del progetto <br />
                </p>
            </div>

        </div>
    );
}

export default Placeholder;
