import React from 'react';
import Item from './Item'
import './Lab.css'

function Lab(props) {
    return (
        <div className='Lab'>
            <div className='wrap'>
               <Item is={true}/>
               <Item is={false}/>
               <Item />
            </div>
        </div>
    );
}

export default Lab;
