import React from 'react';
import linkedin from '../linkedin.svg';
import instagram from '../instagram.svg';
import mail from '../mail.svg';
import './About.css'
function Menu() {
    return (
        <div className='About'>
            <div
                className='headAbout'>
                <div style={{ margin: 5, flex: 1 }}></div>
                <div style={{ margin: 5, textAlign: 'left', flex: 1.5 }}>
                    <h1><strong>Gian Lorenzo Comandini</strong></h1>
                    <p><i>10/12/1993</i></p>
                </div>

            </div>

            <div className='aboutRow'>
                <div className='sectionTitle' ><h2>About</h2></div>
                <div className='sectionContent'  >
                    <p>Based in Rome, <br />
                        I am a creative developer passionate about interaction, design and art.<br />
                        As a freelancer, I work for web agencies, companies, startups and individuals all over the world. <br />
                        <a href='mailto: gianlorenzo@glc.design' target="_blank">Contact me</a> for an ice-breaker chat.
                    </p>
                </div>
            </div>
            <div className='aboutRow'>
                <div className='sectionTitle' ><h2>Experience</h2></div>
                <div className='sectionContent' >
                    <p>
                        <strong>Co-Founder</strong><br /> Orma.digital - <i>Mar. 2021 / Present</i> <br />
                        <strong>User Experience Designer</strong><br /> You&Web - <i>Jun. 2017 / Mar. 2021</i> <br />
                        <strong>Co-Founder</strong> <br /> Star*Tap - <i>Sept. 2017 / Feb. 2020</i><br />
                        <strong>Fullstack Developer</strong> <br /> W.E.L. - <i>Sept. 2018 / Dec. 2018</i><br />
                    </p>

                </div>
            </div>
            <div className='aboutRow'>
                <div className='sectionTitle' ><h2>Education</h2></div>
                <div className='sectionContent' >
                    <p>
                        <strong>Master's Degree in Artificial Intelligence Technology & Management</strong><br /> Ateneo Impresa - <i>May 2021</i> <br />
                        <strong> UX Design</strong><br />Google Career Certificates - <i>Apr. 2021</i> <br />
                        <strong>Bachelor of Interaction Design</strong><br />Quasar Design University - <i>Jul. 2019</i> <br />
                        <strong>UX Management: Strategy and Tactics</strong> <br />The Interaction Design Foundation - <i>Mar. 2019</i> <br />
                        <strong>Quality Web Communication</strong>  <br />The Interaction Design Foundation - <i>May. 2019</i> <br />
                    </p>
                </div>
            </div>
            <div className='aboutRow'>
                <div className='sectionTitle' ><h2>Honors</h2></div>
                <div className='sectionContent' >
                    <p>
                        <strong>Ambassador</strong><br />Luno - <i>Jun. 2019</i> <br />
                        <strong>100 best young creatives </strong><br />Regione Lazio - <i>Feb. 2018</i> <br />
                        <strong>Champion Workshop “Le Soglie del Corpo”</strong><br />Quasar Design University - <i>Mar. 2017</i><br />
                        <strong>Pro Player </strong><br />Rock/Paper/Scissors - <i>Always</i>
                    </p>
                </div>
            </div>
            <div className='aboutRow'>
                <div className='sectionTitle' ><h2>Contacts</h2></div>
                <div className='sectionContent' >
                    <div className='socialIcons' >
                        <a href='https://www.linkedin.com/in/glc-design/' target="_blank"><img href='' alt='Linkedin ' src={linkedin} /></a>
                        <a href='https://www.instagram.com/sempre_burrasca' target="_blank"><img alt='Instagram ' src={instagram} /></a>
                        <a href='mailto: gianlorenzo@glc.design' target="_blank" ><img alt='Email ' src={mail} /></a>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Menu;